<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`이제 다 되었습니다.\n추가로 전달할 내용이\n있나요?`"
      subtitle="상세할수록 더 정확한 견적을 받을 수 있어요."
    >
    </page-header>

    <div class="ui-border-line ui-mt-1" style="background-color: #e4e4e4"></div>

    <div class="ui-textarea">
      <textarea
        v-model="detail"
        @change="() => postOffer = { detail }"
      />

      <div
        v-if="!detail"
        class="ui-textarea-placeholder"
        v-text="placeholder"
      />
    </div>

    <div class="ui-border-line" style="background-color: #e4e4e4"></div>

    <footer-box
      :single="false"
      submitText="견적 요청하기"
      :submitCb="() => submit()"
      :submitDisabled="!validate"
      btnText="미리보기"
      :btnCb="() => $router.push('/offer/post/preview')"
    >
    </footer-box>
  </div>
</template>
그
<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    return {
      detail: '',
      placeholder: '', // 에러방지용
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate() {
      const postOffer = this.postOffer
      let validateArr
      if (!postOffer.genre) return false
      switch (postOffer.genre) {
        case '노래':
          validateArr = ['address', 'eventType', 'genre', 'geoX', 'geoY', 'location', 'playDate', 'playTime', 'priceEnd', 'priceStart', 'wage', 'wgender', 'wmic', 'wsing', 'wtransCost']
          break
        case 'MC':
          validateArr = ['address', 'eventType', 'genre', 'geoX', 'geoY', 'location', 'mcStyle', 'playDate', 'playTime', 'priceEnd', 'priceStart', 'wage', 'wgender', 'wtransCost']
          break
        case '모델':
          validateArr = ['address', 'eventType', 'genre', 'geoX', 'geoY', 'location', 'playDate', 'playTime', 'playLocation', 'priceEnd', 'priceStart', 'wage', 'wgender', 'whairStyle', 'wmakeUp', 'wtransCost']
          break
        default:
          validateArr = ['address', 'eventType', 'genre', 'geoX', 'geoY', 'location', 'playDate', 'playTime', 'priceEnd', 'priceStart', 'wage', 'wgender', 'wtransCost']
          break
      }

      for (let index = 0; index < validateArr.length; index++) {
        const key = validateArr[index]
        const val = postOffer[key]

        if (key === 'playDate' && !this.moment.unix(val).isValid()) {
          return false
        }

        if (/^geo(X|Y)$/.test(key)) {
          return !isNaN(parseFloat(val))
        }
        if (val === null) {
          return false
        }
      }

      return true
    },

  },
  methods: {
    submit() {
      this.postOffer = { detail: this.detail || '' }
      this.$parent.submitPostOffer()
    },
  },
  mounted() {
    this.detail = this.postOffer.detail
  },
}
</script>

<style lang="scss" scoped>

.ui-textarea {
  position: relative;

  textarea {
    position: relative;
    z-index: 1;
    line-height: 3rem;
    padding: 3rem 0;
    border: 0;
    width: 100%;
    min-height: 20rem;
    font-size: 1.6rem;
    background-color: transparent;
  }

  .ui-textarea-placeholder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    line-height: 3rem;
    padding: 3rem 0;
    border: 0;
    width: 100%;
    min-height: 20rem;
    font-size: 2rem;
    color: #b6b6b6;
    white-space: pre-line;
  }
}
</style>
