var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", {
        attrs: {
          title: "이제 다 되었습니다.\n추가로 전달할 내용이\n있나요?",
          subtitle: "상세할수록 더 정확한 견적을 받을 수 있어요."
        }
      }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-1",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c("div", { staticClass: "ui-textarea" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.detail,
              expression: "detail"
            }
          ],
          domProps: { value: _vm.detail },
          on: {
            change: function() {
              return (_vm.postOffer = { detail: _vm.detail })
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.detail = $event.target.value
            }
          }
        }),
        !_vm.detail
          ? _c("div", {
              staticClass: "ui-textarea-placeholder",
              domProps: { textContent: _vm._s(_vm.placeholder) }
            })
          : _vm._e()
      ]),
      _c("div", {
        staticClass: "ui-border-line",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c("footer-box", {
        attrs: {
          single: false,
          submitText: "견적 요청하기",
          submitCb: function() {
            return _vm.submit()
          },
          submitDisabled: !_vm.validate,
          btnText: "미리보기",
          btnCb: function() {
            return _vm.$router.push("/offer/post/preview")
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }